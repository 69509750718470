import React, { Component } from 'react'
import './index.scss'

export default class index extends Component {
    render() {
        return (
            <div className='customer-wrap' id="anchor-cutomer">
                <img alt="" style={{ width: "1.57rem", height: "0.78rem", margin: "0.59rem 0 0.35rem" }} src="./asserts/title_customers.png" />
                <div>
                    <img alt="" className="customer-logo-size" src="./asserts/logo_zglt.png" />
                    <img alt="" className="customer-logo-size" src="./asserts/logo_zgdx.png" />
                    <img alt="" className="customer-logo-size" src="./asserts/logo_hw.png" />
                    <img alt="" className="customer-logo-size" src="./asserts/logo_jdzly.png" />
                </div>
                <div>
                    <img alt="" className="customer-logo-size" src="./asserts/logo_zgcsz.png" />
                    <img alt="" className="customer-logo-size" src="./asserts/logo_xlw.png" />
                    <img alt="" className="customer-logo-size" src="./asserts/logo_gxzc.png" />
                    <img alt="" className="customer-logo-size" src="./asserts/logo_cyrj.png" />
                </div>
                <div style={{ height: "0.2rem" }}>
                </div>
            </div>
        )
    }
}
