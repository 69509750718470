import axios from 'axios'

const service = axios.create({
    baseURL: process.env.REACT_APP_TARGET,
    timeout: 5000,
})

// 添加请求拦截器
service.interceptors.request.use(function (config) {
    console.log(process.env.REACT_APP_TARGET);
    config.headers = {
        'Content-Type': 'application/json',
        'token': 'this is token'  // 看到这个token了没有，它在在第一步的第三行出现过~
    };
    return config;
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
});

// 添加响应拦截器
service.interceptors.response.use(function (response) {
    // 对响应数据做点什么
    return response;
}, function (error) {
    // 对响应错误做点什么
    return Promise.reject(error);
});

export default service