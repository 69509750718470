let loading = document.getElementById('ajaxLoading');

function display_loading(flag) {
    if (flag === true) {
        loading.style.display = "block";
    } else if (flag === false) {
        loading.style.display = "none";
    }
}

export default display_loading;
