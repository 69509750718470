import React, { Component } from 'react';
import { Popover } from 'antd';
import './index.scss'

class index extends Component {
    render() {

        const rightZoneBtn = (
            <div>
                <p className="welcome-call-text">欢迎致电专家助手青云： </p>
                <div style={{ display: "flex" }}><p className="call-number-text">17600071809</p><p className="welcome-call-text">进行咨询！</p></div>

            </div>
        );
        return (
            <div className="pic-zone-wrap" id="anchor-home">
                <div className="pic-zone-img">
                    <img alt="" style={{ width: "3.45rem", height: "4.95rem", marginRight: "0.8rem" }} src="./asserts/xcxsjt.png"></img>
                </div>
                <div className="pic-zone-text">
                    <p className="right-zone-line1">院士领衔的高层次专家库</p>
                    <p className="right-zone-line2">跨学科、多领域、高层次专家库</p>
                    <Popover content={rightZoneBtn} placement="bottom">
                        <div className="right-zone-btn">咨询专家助手</div>
                    </Popover>
                </div>
            </div>
        )
    }
}
export default index;
