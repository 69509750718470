import React, { Component } from 'react'
import './index.scss'

class SolutionGrid extends Component {
    render() {
        return (
            <div className='solution-wrap' id="anchor-solution">
                <img alt='' style={{ width: "1.47rem", height: "0.78rem", margin: "0.56rem 0 0.55rem" }} src="./asserts/title_solutions.png" />
                <div className="solution-items">
                    <div className="solution-item">
                        <p className="text-title">行业服务解决方案（企业版）</p>
                        <p className="text-item">产业情报分析</p>
                        <p className="text-item">财政资金辅导</p>
                        <p className="text-item">高端品牌传播</p>
                        <p className="text-item">产学研项目合作</p>
                    </div>
                    <div className="solution-item">
                        <p className="text-title">学科服务解决方案（院校版）</p>
                        <p className="text-item">学科建设把脉</p>
                        <p className="text-item">项目课题辅导</p>
                        <p className="text-item">高层次人才引进</p>
                        <p className="text-item">产学研项目合作</p>
                    </div>
                </div>
                <div className="solution-items">
                    <div className="solution-item">
                        <p className="text-title">科研服务解决方案（科研机构版）</p>
                        <p className="text-item">产业需求对接</p>
                        <p className="text-item">政策解读分析</p>
                        <p className="text-item">成果转发传播</p>
                        <p className="text-item">产学研项目合作</p>
                    </div>
                    <div className="solution-item">
                        <p className="text-title">区域服务解决方案（地方政府版）</p>
                        <p className="text-item">区域创新指导</p>
                        <p className="text-item">政策解读分析</p>
                        <p className="text-item">高层次人才引进</p>
                        <p className="text-item">产学研项目合作</p>
                    </div>
                </div>
            </div>
        )
    }
}
export default SolutionGrid;
