import service from '../utils/request'
/**
 * 验证码
 */
export function GetCode(data) {
    return service.request({
        url: '/ucpaas/create',
        method: 'post',
        data: data, // 请求类型为POST时
        //params: data // 请求类型为GET时
    })
}

/**
 * 提交需求
 */
export function RequirementAdd(data) {
    return service.request({
        url: '/requirement/create?XDEBUG_SESSION_START=11302',
        method: 'post',
        data: data, // 请求类型为POST时
        //params: data // 请求类型为GET时
    })
}
