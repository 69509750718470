import React, { Component } from 'react'
import "./index.scss";
import { Row, Col, Image, Affix, Popover } from 'antd';

class index extends Component {
    state = {
        flag: true
    }
    closeAffix = () => {
        let flag = this.state.flag
        this.setState({
            flag: !flag
        })
        //console.log(this.state.flag)
    }
    render() {
        const askBtn = (
            <div>
                <p className="welcome-call-text">欢迎致电专家助手青云： </p>
                <div style={{ display: "flex" }}><p className="call-number-text">17600071809</p><p className="welcome-call-text">进行咨询！</p></div>

            </div>
        );
        const { flag } = this.state;
        return (

            <div className='footer-zone-wrap'>
                <Row>
                    <Col span={3} ></Col>
                    <Col span={9} className="footer-left">
                        <div>
                            <Image style={{ width: "2.38rem", height: "0.49rem", marginBottom: "0.28rem" }} src="./asserts/logo_tronedu.png" />
                            <p className="footer-contact-us">联系我们Contact</p>
                            <p>手机：+86 17600071809（工作日09:00-18:00）</p>
                            <p>网址：www.tronedu.com</p>
                            <p>邮箱：service@tronedu.com</p>
                            <p>地址：北京市海淀区上地东路1号院5号楼207</p>
                        </div>
                    </Col>
                    <Col span={9}>
                        <div className="footer-right">
                            <div className="footer-right-item">
                                <Image style={{ width: "1.9rem", height: "1.9rem", marginRight: "0.1rem", marginBottom: "0.01rem" }} src="./asserts/qrcode_tronedu.png" />
                                <p className="qrcode-text">“创壹智库”微信公众号</p>
                            </div>
                            <div className="footer-right-item">
                                <Image style={{ width: "1.9rem", height: "1.9rem", marginBottom: "0.01rem" }} src="./asserts/qrcode_zjzsqygrwx.png" />
                                <p className="qrcode-text">专家助手青云个人微信</p>
                            </div>
                        </div>
                    </Col>
                    <Col span={3} ></Col>
                </Row>
                <Row>
                    <Col span={3} ></Col>
                    <Col span={18}>
                        <div className="footer-copyright">
                            <p>Copyright© 2020 创壹智库  京ICP备19058531号-1</p>
                        </div>
                    </Col>
                    <Col span={3} ></Col>
                </Row>
                <Affix offsetBottom={100} style={{ display: flag ? 'block' : 'none', pointerEvents: 'none' }}>
                    <div className="affix-wrap">
                        <div className="affix-box-1">
                            <img alt="" onClick={this.closeAffix} style={{ width: "0.13rem", height: "0.14rem", pointerEvents: 'auto' }} src="./asserts/icon_close.png" />
                            <div className="affix-box-2">
                                <Image style={{ width: "1.38rem", height: "1.38rem" }} src="./asserts/qrcode_zjzsqygrwx.png" />
                                <p className="affix-scan-text">扫码咨询专家助手</p>
                                <div className="affix-btn" style={{ pointerEvents: 'auto' }}>
                                    <img style={{ width: "0.28rem", height: "0.28rem" }} alt="" src="./asserts/icon_call.png"></img>
                                    <Popover content={askBtn} placement="bottom">
                                        <p style={{ marginLeft: "0.02rem" }}>电话咨询</p>
                                    </Popover>
                                </div>
                            </div>
                        </div>
                    </div>
                </Affix>
            </div>
        )
    }
}
export default index;
