import React, { Component } from 'react'
import { Image, Anchor, Popover } from 'antd'
import './index.scss'
const { Link } = Anchor;
class index extends Component {
    render() {
        const content1 = (
            <div>
                <p className="welcome-call-text">欢迎致电专家助手青云： </p>
                <div style={{ display: "flex" }}><p className="call-number-text">17600071809</p><p className="welcome-call-text">进行咨询！</p></div>

            </div>
        );
        const content2 = (
            <div style={{ textAlign: "center" }}>
                <Image style={{ width: "1.38rem", height: "1.38rem" }} src="./asserts/qrcode_tronedu.png" />
                <p className="scan-text">扫码关注创壹智库</p>
            </div>
        );
        return (
            <Anchor targetOffset={80} >
                <div className='nav-wrap'>
                    <img alt="" style={{ width: "1.7rem", height: "0.47rem", marginRight: "0.42rem" }} src="./asserts/logo.png"></img>
                    <Link className="nav-menu-item" href="#anchor-home" title="首页" />
                    <div className="nav-menu-item">|</div>
                    <Link className="nav-menu-item" href="#anchor-talent" title="智库专家" />
                    <div className="nav-menu-item">|</div>
                    <Link className="nav-menu-item" href="#anchor-service" title="专家服务" />
                    <div className="nav-menu-item">|</div>
                    <Link className="nav-menu-item" href="#anchor-solution" title="解决方案" />
                    <div className="nav-menu-item">|</div>
                    <Link className="nav-menu-item" href="#anchor-advantage" title="智库优势" />
                    <div className="nav-menu-item">|</div>
                    <Link className="nav-menu-item" href="#anchor-cutomer" title="服务案例" />
                    <div className="nav-menu-item">|</div>
                    <Link className="nav-menu-item" href="#anchor-introduction" title="关于我们" />
                    <Popover content={content1} placement="bottom">
                        <div className="nav-btn-1">咨询专家助手</div>
                    </Popover>
                    <Popover content={content2} placement="bottom">
                        <div className="nav-btn-2">关注创壹智库</div>
                    </Popover>


                </div>
            </Anchor >
        )
    }
}
export default index;
