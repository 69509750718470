import React, { Component } from 'react'
import { Button } from 'antd'
import "./agreement.scss"

class agreement extends Component {
    render() {
        window.scrollTo(0, 0);
        return (
            <div className="agreement-wrap">
                <div className="title">创壹智库用户服务协议</div>
                <div className="content">感谢您选择创壹智库，请您在入驻创壹智库及使用本平台服务之前审慎阅读本协议，本协议具有合同效力（未成年人应在法定监护人陪同下审阅）。</div>
                <div>若您已经注册为创壹智库用户，即表示您已充分阅读、理解并同意自己与创壹智库订立本协议包括创壹智库免责条款在内的所有条款，且您已准确理解其含义，自愿承担对该种风险及可能带来的各种损失。创壹智库有权随时变更本协议并在本网站上予以公告。</div>
                <div>风险提示：因用户自身疏忽导致账号或密码泄露，可能会给用户造成的损失，用户自己承担其风险。</div>

                <div className="level-1-title">第一条【定义】</div>
                <div>针对本协议中频繁出现的词语解释说明：</div>
                <div>1.1创壹智库/本网站：是指北京创壹教育科技有限公司打造的线上服务平台，创壹智库（域名：http://www.tronedu.com）。</div>
                <div>1.2用户：指符合本协议所规定的条件，同意遵守创壹智库各种规则、条款（包括但不限于本协议），并使用创壹智库服务的自然人或法人（未成年人应在监护人陪同下使用本网站）。</div>
                <div>1.3个人信息：在本协议中，是指以电子或者其他方式记录的能够单独或者与其他信息结合识别用户身份或者反映用户活动情况的各种信息。</div>
                <div>1.4本协议内容包括协议正文、附件以及各类业务规范、活动规则、通知、公告等（以下统 称为“创壹智库规则”）。所有创壹智库规则为本协议不可分割的组成部分，与本协议正文具有同等法律效力。</div>
                <div>1.5您：指用户。</div>
                <div>1.6服务：指创壹智库向用户提供的服务。</div>

                <div className="level-1-title">第二条【协议生效及变更】</div>
                <div>2.1本协议只对注册使用创壹智库的用户有效。当您完成用户注册程序后或以任何方式使用本网站的服务时即表示您已经充分阅读并理解本协议内容，本协议立即生效，并对您具有同书面协议一样的法律约束力，您需对您在本网站的所有操作负责。</div>
                <div>2.2创壹智库只是一个服务交易平台，为了给用户提供更好的服务，我们有权根据公司经营状况、经营策略的变化，法律法规、政策内容的变化来调整、增减本协议内容。本协议一经修改，第一时间通知已注册用户悉知。若您对变更后协议有疑问，可致电创壹智库客服；如果您不同意变更后协议条款，可主动放弃本网站使用权；如您继续使用本网站服务，表示您同意接受修改后条款。</div>
                <div>2.3当用户发生争议时，以最新版本的用户服务协议为准。</div>


                <div className="level-1-title">第三条【用户注册】</div>
                <div>3.1本网站是为用户提供多元化用户服务资源的平台，用户在本网站注册账号时，应遵守互联网起名规范和本协议相关规定，注册名不得出现违规违法和不良信息。如若发现用户注册名称违规，创壹智库有权采取限期改正、暂停使用、注销登录等措施，并有权拒绝对您提供服务。</div>
                <div>3.2为保障用户合法权益，高效快速为用户匹配服务机构，用户必须保证在注册创壹智库时所提供的信息真实有效，且符合平台规定，否则本网站有权拒绝为您提供服务。</div>
                <div>3.3用户应妥善保管用户账号、密码和手机短信验证码，避免使用过于简单的密码。用户在服务使用过程中可以根据本网站规定更改密码。用户账号和密码不得出借、转让、继承或者赠与。因非本网站原因导致用户的账号、密码遗失、遗忘或被他人窃取的，本网站不承担责任。如果用户发现自己的个人信息泄密，尤其是本网站账户及密码发生泄露，请立即联络创壹智库。</div>
                <div>3.4用户通过账号、密码和/或手机短信验证码登录后使用本网站的行为，均视为用户本人行为，用户应当承担由此导致的相关后果和责任。</div>
                <div>3.5用户登录后，可以设置用户在本网站的昵称。用户账号、昵称应当符合法律法规规定，不得违反法律法规规定、侵犯或涉嫌侵犯他人合法权益，否则，创壹智库有权暂停或终止提供本网站的所有服务，并冻结用户账号。</div>


                <div className="level-1-title">第四条【本网站职责范围】</div>
                <div>在使用本网站服务和同意本协议之前，您应当明确了解并同意本网站的职责范围：</div>
                <div>4.1本网站有义务在现有技术水平的基础上努力确保整个网上交流平台的正常运行，尽力避免服务中断或将中断时间限制在最短时间内，保证用户网上交流活动的顺利进行。</div>
                <div> 4.2本网站只是为用户提供一个交易服务的平台，网络环境复杂多变，用户在本网站获取任何资料、建议、资讯等信息，本网站不能保证其都真实、有效，用户应小心谨慎并运用常识，不可轻信他人言论。本网站会谨慎审核用户所发布需求，但对用户发布需求真实性合法性不做担保，用户在使用本网站服务过程中应自行衡量并自负风险。</div>
                <div>4.3本网站上出现的任何其他平台或资源链接，均由此链接提供者自行负责，不属本网站控制及职责范围，其风险未可知，用户应谨慎选择，如因此类链接造成风险损失，本网站不负任何直接、间接责任。</div>



                <div className="level-1-title">第五条【用户的权利与义务】</div>
                <div>5.1用户使用本协议项下服务，应当遵守《中华人民共和国网络安全法》《中华人民共和国计算机信息网络国际联网暂行规定》和其他有关法律、法规或相关规定、以及本协议的约定，不得存在任何违法违规行为，不得侵犯创壹智库以及任何第三方的合法权益。</div>
                <div>5.2 在遵守本协议的前提下，用户有权使用本网站提供的网络服务。用户同意通过本站消息通知及创壹智库官方端口短信消息接收服务费、提醒信息及用户关怀、营销活动等最新的优惠产品和服务的相关信息。如用户不愿意接受相关信息，可以通过客服电话17600071809或按照短信消息中退订提示进行退订。</div>
                <div>5.3 本网站所提供的网络服务需要获得用户授权终端设备的部分信息方可正常使用；本网站按照相关法律法规，以最小化权限原则、区分网络服务场景进行授权获取的提示。用户有权不同意，但可能会影响本网站或相应网络服务的正常使用。</div>
                <div>5.4 用户承诺使用网络服务应当符合国家法律法规的规定，且行为符合下列要求：</div>
                <div>(1)不干扰本网站的正常运转，不进行任何破坏或试图破坏网络安全的行为，不进行任何改变或试图改变本网站系统配置或破坏系统安全的行为，不得侵入本网站及国家计算机信息系统；</div>
                <div>(2)不对本网站展示或提供的、由创壹智库所有的任何数据或信息作商业性利用，包括但不限于在未经创壹智库事先书面同意的情况下，以复制、传播等任何方式使用本网站展示或提供的、由创壹智库所有的资料、信息、数据等；</div>
                <div>(3)不模仿、修改、翻译、改编、出借、出售、转许可、传播或转让本网站提供的服务，也不得逆向工程、反汇编、反编译、分解拆卸或试图以其他方式发现本网站提供的服务的源代码；</div>
                <div>(4)不发送、上传和储存带有病毒的、蠕虫的、木马和其他有害内容的计算机代码、文件、脚本和程序；</div>
                <div>(5)遵守所有使用网络服务的网络协议、规定、程序和惯例；</div>
                <div>(6)不从事其他违反法律法规规定或双方约定的行为。</div>
                <div>5.5 用户不得通过本网站制作、复制、发布、传播含有下列内容的信息：</div>
                <div>（1）反对宪法所确定的基本原则的；</div>
                <div>（2）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</div>
                <div>（3）损害国家荣誉和利益的；</div>
                <div>（4）煽动民族仇恨、民族歧视，破坏民族团结的；</div>
                <div>（5）破坏国家宗教政策，宣扬邪教和封建迷信的；</div>
                <div>（6）散布谣言，扰乱社会秩序，破坏社会稳定的；</div>
                <div>（7）散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；</div>
                <div>（8）侮辱或者诽谤他人，侵害他人合法权益的；</div>
                <div>（9）含有法律、行政法规禁止的其他内容的。</div>
                <div>5.6 若用户存在违反法律规定或本协议约定的任一情形，本网站有权按相关规定暂停或终止提供服务，保存有关记录，并向政府主管部门报告，由此引起的一切后果和责任由用户负责。同时，创壹智库有权终止本协议，并不承担任何责任。</div>
                <div>5.7 用户违反本协议使用网络服务导致创壹智库或第三人损失的，应当承担相应法律责任、赔偿创壹智库或第三人的损失。</div>

                <div className="level-1-title"> 第六条【创壹智库的权利与义务】</div>
                <div>6.1创壹智库仅作为一个为用户提供信息交流和专家服务交易的平台，本网站不主动介入交易过程。</div>
                <div>6.2本网站有义务保证平台运转流畅，对平台突发情况在最短时间内做出响应，对用户使用本网站时的任何疑问和问题及时回复。同时，本网站也有权利对用户注册信息和发布需求进行审查和问询，并要求改正。</div>
                <div>6.3因网上信息交易平台的特殊性，用户应谨慎进行交易，本网站对用户交易过程中的任何事宜不承担事先审查义务，若用户因在本网站网上交易而导致纠纷，本网站悉知后，有权向纠纷双方了解情况，如用户要走司法程序，本网站有权配合司法部门提供资料。</div>
                <div>6.4若用户存在违反国家法律法规或本协议、本网站其它规则的不当行为且有事实依据，本网站有权无需征得用户同意做出恰当处理。</div>


                <div className="level-1-title">第七条【消费者权益保护】</div>
                <div>7.1本网站的网络商品是服务机构自行提供，创壹智库有义务审核其真实有效性。如若发生争议，用户可自行与服务机构进行沟通处理，如无法达成一致意见，可请求创壹智库作为第三方进行调解，创壹智库根据公平、公正、公开原则进行调解。如对创壹智库调解结果不满意，双方可通过其他合法渠道进行协商，创壹智库应积极提供相关协助。</div>

                <div className="level-1-title">第八条【知识产权】</div>
                <div>8.1创壹智库的网页、客户端、移动端产品等的著作权、商标权、专利权、商业秘密等知识产权、其他相关权利均独立归属创壹智库所有。未经创壹智库及其他相关权利人同意，用户不得以任何方式或任何途径实施发布、复制、出版等行为，不论是否以商业为目的。</div>
                <div>8.2本站所刊登的资料信息（诸如文字、图表、标识、按钮图标、图像、声音文件片段、数字下载、数据编辑和软件），均是本网站或其内容提供者的财产，受中国和国际版权法的保护。本站上所有内容的汇编是本网站的排他财产，受中国和国际版权法的保护。本站上所有软件都是本网站或其关联公司或其软件供应商的财产，受中国和国际版权法的保护。</div>

                <div className="level-1-title">第九条【隐私权】</div>
                <div>9.1创壹智库非常重视用户个人信息的保护，创壹智库通过本网站向用户提供服务时，将按照相关法律法规的规定收集、存储、使用、共享、转移、公开披露、保护和管理用户信息和隐私。创壹智库不会向任何人出售、出借或披露任何用户信息，除非得到用户同意或者根据相关法律规定。创壹智库也不允许任何第三方通过任何形式收集传播使用网站用户信息，一经发现，严肃处理。</div>
                <div>9.2用户在使用本网站时请妥善保护您的个人信息，如若信息泄露，请立即联系本网站客服，以便我们采取相应措施。</div>
                <div>9.3用户未能按照本网站的要求和内容提供本网站要求的必要信息的，本网站有权中止本网站所提供的对应服务，由此所导致的任何已经或可能发生的损失，由用户自行承担，本网站并保留对该用户进行追索的权利。本网站有权对用户的信息及相关操作进行查阅，发现信息或相关操作中存在任何问题或怀疑，均有权向用户发出询问及要求改正的通知或者直接作出删除账户等处理。</div>

                <div className="level-1-title">第十条【不可抗力】</div>
                <div>10.1因不可抗力或者其他意外事件，使得本协议的履行不可能、不必要或者无意义的，双方均不承担责任。本协议所称之不可抗力指不能预见、不能避免并不能克服的客观情况，包括但不限于战争、台风、水灾、火灾、雷击或地震、罢工、暴动、法定疾病、黑客攻击、网络病毒、电信部门技术管制、政府行为或任何其它自然或人为造成的灾难等客观情况。</div>

                <div className="level-1-title">第十一条【其他】</div>
                <div>11.1本协议旨在更好更便利为用户提供服务，如若用户对本协议有任何异议，可联系本网站客服。</div>
                <div>11.2因双方就本协议的签订、履行或解释发生争议，双方应持平等、友好、争取合理解决问题的态度协商解决；如协商未成，任何一方均应向创壹智库运营中心所在地人民法院提起诉讼。</div>
                <div>11.3本协议各项规则最终解释权归创壹智库所有。</div>
                <div>11.4本协议自公布之日起生效。</div>

                <Button style={{ marginTop: "20px" }} type="primary" onClick={() => this.props.history.push('/')}>回到主页</Button>
            </div>
        )
    }
}
export default agreement;