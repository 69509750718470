import React, { Component } from 'react'
import "./index.scss";
import { Card } from 'antd';

class index extends Component {
    render() {
        return (
            <div className='service-wrap' id="anchor-service">
                <img alt='' style={{ width: "1.52rem", height: "0.78rem", margin: "0.58rem 0 0.38rem" }} src="./asserts/title_services.png" />
                <div className="service-items">
                    <Card
                        style={{ height: "2.8rem", marginRight: "0.11rem" }}
                        bodyStyle={{ padding: 0, textAlign: "center" }}
                        hoverable
                        bordered={false}
                        cover={
                            <div style={{ width: "2.91rem", height: "1.56rem", display: "flex", alignItems: "center", justifyContent: "center" }}><img style={{ width: "0.64rem", height: "0.73rem" }} alt="example" src="./asserts/icon_zjzx.png" /></div>
                        }
                    >
                        <p className="service-info">专家咨询</p>
                        <p className="service-info-extends">专家提供一对一咨询服务</p>
                    </Card>
                    <Card
                        style={{ height: "2.8rem", marginRight: "0.11rem" }}
                        bodyStyle={{ padding: 0, textAlign: "center" }}
                        hoverable
                        bordered={false}
                        cover={
                            <div style={{ width: "2.91rem", height: "1.56rem", display: "flex", alignItems: "center", justifyContent: "center" }}><img style={{ width: "0.7rem", height: "0.69rem" }} alt="example" src="./asserts/icon_zjcx.png" /></div>
                        }
                    >
                        <p className="service-info">专家出席</p>
                        <p className="service-info-extends">专家出席会议、论坛、讲座等</p>
                    </Card>
                    <Card
                        style={{ height: "2.8rem", marginRight: "0.11rem" }}
                        bodyStyle={{ padding: 0, textAlign: "center" }}
                        hoverable
                        bordered={false}
                        cover={
                            <div style={{ width: "2.91rem", height: "1.56rem", display: "flex", alignItems: "center", justifyContent: "center" }}><img style={{ width: "0.51rem", height: "0.65rem" }} alt="example" src="./asserts/icon_rcps.png" /></div>
                        }
                    >
                        <p className="service-info">人才评审</p>
                        <p className="service-info-extends">高层次人才评审、职称评审等</p>
                    </Card>
                </div>
                <div className="service-items">
                    <Card
                        style={{ height: "2.8rem", marginRight: "0.11rem" }}
                        bodyStyle={{ padding: 0, textAlign: "center" }}
                        hoverable
                        bordered={false}
                        cover={
                            <div style={{ width: "2.91rem", height: "1.56rem", display: "flex", alignItems: "center", justifyContent: "center" }}><img style={{ width: "0.71rem", height: "0.73rem" }} alt="example" src="./asserts/icon_ktps.png" /></div>
                        }
                    >
                        <p className="service-info">课题评审</p>
                        <p className="service-info-extends">立项评审、结题评审、成果评审</p>
                    </Card>
                    <Card
                        style={{ height: "2.8rem", marginRight: "0.11rem" }}
                        bodyStyle={{ padding: 0, textAlign: "center" }}
                        hoverable
                        bordered={false}
                        cover={
                            <div style={{ width: "2.91rem", height: "1.56rem", display: "flex", alignItems: "center", justifyContent: "center" }}><img style={{ width: "0.89rem", height: "0.76rem" }} alt="example" src="./asserts/icon_xmhz.png" /></div>
                        }
                    >
                        <p className="service-info">项目合作</p>
                        <p className="service-info-extends">跨行业、地域、机构的产学研合作</p>
                    </Card>
                    <Card
                        style={{ height: "2.8rem", marginRight: "0.11rem" }}
                        bodyStyle={{ padding: 0, textAlign: "center" }}
                        hoverable
                        bordered={false}
                        cover={
                            <div style={{ width: "2.91rem", height: "1.56rem", display: "flex", alignItems: "center", justifyContent: "center" }}><img style={{ width: "0.55rem", height: "0.72rem" }} alt="example" src="./asserts/icon_ycyz.png" /></div>
                        }
                    >
                        <p className="service-info">引才引智</p>
                        <p className="service-info-extends">按需精准匹配高层次专家及团队</p>
                    </Card>
                </div>
            </div>


        )
    }
}
export default index;
