import React, { Component } from 'react'
import { Form, Button, Input, message, Checkbox, Row, Col, Image, Modal } from 'antd'
import Code from '../Code/code'
// 路由
import { withRouter } from 'react-router-dom'
// API
import { RequirementAdd } from '../../api/requirement'
// 加载
import display_loading from "../../utils/loading";
import './requirement.scss'


const { TextArea } = Input;
class requirement extends Component {
    state = {
        mobile: '',
    }
    formRef = React.createRef();
    onChange = (event) => {
        this.setState({
            mobile: event.target.value
        });
    }

    onReset = () => {
        this.formRef.current.resetFields();
    };

    onFinish = (value) => {
        console.log(value);

        let requestData = {
            mobile: this.state.mobile,
            company: value.company,
            name: value.name,
            vcode: value.vcode,
            summary: value.summary
        }
        console.log(requestData);
        display_loading(true);
        RequirementAdd(requestData).then(response => {
            display_loading(false);
            let result = response.data;
            if (result.errorCode === 0) {
                // message.success(result.msg, 3);
                this.formRef.current.resetFields();
                this.submitSuccessModal()
                return;
            }
            message.warn(result.msg, 1);
        }).catch(error => {
            display_loading(false);
        })


    }


    submitSuccessModal = () => {
        const modal = Modal.success({
            title: '感谢您的需求提交',
            content: `我们会在第一时间与您联系,请保持手机畅通`,
        });

    }

    onClickAgreement = () => {
        this.props.history.push('/agreement')
    }

    render() {
        const layout = {
            labelCol: {
                span: 6,
            },
            wrapperCol: {
                span: 18,
            },
        };
        const textarealayout = {
            wrapperCol: {
                offset: 0,
                span: 24,
            },
        };
        const tailLayout = {
            wrapperCol: {
                offset: 0,
                span: 24,
            },
        };
        const validateMessages = {
            required: '${label} is required!',
            types: {
                email: '${label} is not a valid email!',
                number: '${label} is not a valid number!',
            },
            number: {
                range: '${label} must be between ${min} and ${max}',
            },
        };

        return (
            <div className="requirement-wrap">
                <Row>
                    <Col span={24} style={{ textAlign: "center" }}>
                        <Image style={{ width: "1.8rem", height: "0.78rem", margin: "0.54rem 0 0.16rem 0" }} src="./asserts/title_expressway.png" />
                    </Col>
                </Row>
                <Row>
                    <Col span={2} ></Col>
                    <Col span={20}>
                        <p className="requirement-title">
                            隐私保护：您所填信息仅专家及专家助手可见，请放心填写。
                        </p>
                    </Col>
                    <Col span={2} ></Col>
                </Row>
                <Row>
                    <Col span={4} ></Col>
                    <Col span={16}>
                        <Form
                            {...layout}
                            ref={this.formRef}
                            name="basic"
                            initialValues={{
                                summary: '',
                                remember: false
                            }}
                            onFinish={this.onFinish}
                            validateMessages={validateMessages}
                        >
                            <div className="requirement-area-title">
                                需求简介
                            </div>
                            <Form.Item
                                {...textarealayout}
                                label=""
                                name="summary"
                            >
                                <TextArea
                                    className="requirement-description"
                                    allowClear
                                    rows={4}
                                    placeholder="请介绍您的活动主题、主办方、活动时间、活动地点、拟邀专家、预算范围等，以及需要专家配合哪些工作。"
                                />
                            </Form.Item>


                            <div className="requirement-area-title">
                                需求方信息
                    </div>

                            <Form.Item
                                className="requirement-item-title"
                                label="单位名称*"
                                name="company"
                                rules={[
                                    {
                                        required: true,
                                        message: "单位名称是必填项"
                                    }, {
                                        min: 4, max: 30,
                                        message: "至少4个字，至多30个字"
                                    }
                                ]}
                            >
                                <Input className="requirement-item-input" allowClear placeholder="请填写完整单位名称" />
                            </Form.Item>

                            <Form.Item
                                className="requirement-item-title"
                                label="联系人姓名*"
                                name="name"
                                rules={[
                                    {
                                        required: true,
                                        message: "联系人姓名是必填项"
                                    }, {
                                        min: 2, max: 15,
                                        message: "至少2个字，至多15个字"
                                    }
                                ]}
                            >
                                <Input className="requirement-item-input" allowClear placeholder="请填写真实姓名" />
                            </Form.Item>

                            <Form.Item
                                className="requirement-item-title"
                                label="手机号码*"
                                name="mobile"
                                rules={[
                                    {
                                        required: true,
                                        message: "手机号码是必填项"
                                    }, {
                                        len: 11,
                                        message: "11位"
                                    }
                                ]}
                            >
                                <Input className="requirement-item-input" value={this.mobile} onChange={this.onChange} maxLength={11} allowClear placeholder="请填写常用手机号码" />
                            </Form.Item>
                            <Form.Item
                                className="requirement-item-title"
                                label="手机号验证*"
                                name="vcode"
                                rules={[
                                    {
                                        required: true,
                                        message: "验证码是必填项"
                                    }, {
                                        len: 4,
                                        message: "4位"
                                    }
                                ]}
                            >
                                <div className="requirement-item-right">
                                    <Input className="requirement-item-input" maxLength={4} allowClear placeholder="请输入验证码" />
                                    <Code mobile={this.state.mobile} />
                                </div>
                            </Form.Item>
                            <Form.Item {...tailLayout} name="remember" valuePropName="checked" rules={[
                                {
                                    validator: (_, value) =>
                                        value ? Promise.resolve() : Promise.reject('请先同意用户协议'),
                                },
                            ]}>
                                <div className="requirement-agreement">
                                    <Checkbox></Checkbox>
                                    <div className="requirement-agreement-agree-text">
                                        我已同意
                                    </div>
                                    <div className="requirement-agreement-detail" onClick={this.onClickAgreement}>
                                        《创壹智库用户服务协议》
                                    </div>
                                </div>
                            </Form.Item>
                            <Button className="requirement-btn-submit" type="primary" htmlType="submit">
                                提交需求
                            </Button>
                        </Form>
                    </Col>
                    <Col span={4} ></Col>
                </Row>

            </div>
        )
    }
}
export default withRouter(requirement)
