import React, { Component } from 'react'
import './index.scss'
import { Row, Col, Image } from 'antd';

class index extends Component {
    render() {
        return (
            <div className='advantage-wrap' id="anchor-advantage">
                <Row>
                    <Col span={24} >
                        <Image style={{ width: "1.66rem", height: "0.78rem", margin: "0.56rem" }} src="./asserts/title_advantages.png" />
                    </Col>
                </Row>
                <Row>
                    <Col span={4}></Col>
                    <Col span={16}>
                        <div className="advantage-item">
                            <div className="advantage-item-left">
                                <div className="number">
                                    <p>1</p>
                                </div>
                            </div>
                            <div className="advantage-item-right">
                                <div className="text">
                                    <p className="advantage-item-right-line1">百位院士领衔的高层次专家库</p>
                                    <p className="advantage-item-right-line2">精准匹配高层次专家，快速锁定两院院士，国家级、省部级专家行程。</p>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col span={4}></Col>
                </Row>
                <Row>
                    <Col span={4}></Col>
                    <Col span={16}>
                        <div className="advantage-item">
                            <div className="advantage-item-left">
                                <div className="number">
                                    <p>2</p>
                                </div>
                            </div>
                            <div className="advantage-item-right">
                                <div className="text">
                                    <p className="advantage-item-right-line1">定制化专家服务解决方案</p>
                                    <p className="advantage-item-right-line2">灵活对接政、产、学、研各方面高端资源，为不同主体提供定制化解决方案。</p>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col span={4}></Col>
                </Row>
                <Row>
                    <Col span={4}></Col>
                    <Col span={16}>
                        <div className="advantage-item">
                            <div className="advantage-item-left">
                                <div className="number">
                                    <p>3</p>
                                </div>
                            </div>
                            <div className="advantage-item-right">
                                <div className="text">
                                    <p className="advantage-item-right-line1">专家助手全流程一对一服务</p>
                                    <p className="advantage-item-right-line2">经验丰富的专家助手全流程一对一跟踪服务，确保专家服务高质量、高效完成。</p>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col span={4}></Col>
                </Row>
                <Row>
                    <Col span={24} style={{ height: "0.3rem" }}></Col>
                </Row>
            </div >
        )
    }
}
export default index;
