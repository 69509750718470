import React, { Component } from 'react'
import "./index.scss";
import { Row, Col, Image } from 'antd';

class index extends Component {
    render() {
        return (
            <div className='introduction-wrap' id="anchor-introduction">
                <Row>
                    <Col span={24} >
                        <Image style={{ width: "1.71rem", height: "0.78rem", margin: "0.54rem 0 0.16rem 0" }} src="./asserts/title_introduction.png" />
                    </Col>
                </Row>
                <Row>
                    <Col span={2} ></Col>
                    <Col span={20}>
                        <p className="introduction-title">
                            跨学科、多领域、高层次专家服务
                        </p>
                    </Col>
                    <Col span={2} ></Col>
                </Row>
                <Row>
                    <Col span={2} ></Col>
                    <Col span={20}>
                        <div className="introduction-detail">
                            <p>创壹智库（TRONEDU）以“服务专家、专家服务”为宗旨，构建了全球跨学科、多领域、高层次专家库，由北京创壹教育科技有限公司发起，联合中国专家科技经济咨询中心，紧密连接海内外高层次专家资源，包括院士、长江学者、杰出青年、海外高层次人才、万人计划专家、国家百千万人才、国务院政府特殊津贴专家、博士后人才、产业专家等，为各级政府及高校、科研院所、国有企业等企事业单位提供专家咨询、专家出席、专家评审、专家辅导、项目合作、引才引智等特色专家服务及解决方案，致力于推动“政产学研用”各方资源深度协同，助力实现高层次人才引领高质量发展。</p>
                        </div>
                    </Col>
                    <Col span={2} ></Col>
                </Row>
            </div>
        )
    }
}
export default index;
