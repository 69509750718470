import React, { Component } from 'react'
import "./index.scss";
import { Row, Col, Image, Card } from 'antd';

class TalentGrid extends Component {
    render() {
        return (
            <div className='talent-grid-wrap' id="anchor-talent">
                <Row>
                    <Col span={24} >
                        <Image style={{ width: "1.4rem", height: "0.78rem", margin: "0.57rem 0 0.29rem 0" }} src="./asserts/title_talents.png" />
                    </Col>
                </Row>
                <Row>
                    <Col span={3} ></Col>
                    <Col span={6}>
                        <Card
                            hoverable
                            bordered={false}
                            style={{ margin: "0 0.1rem 0.1rem 0" }}
                            bodyStyle={{ padding: "0.1rem 0 0.1rem 0" }}
                        >
                            <img style={{ width: "1.44rem", height: "1.44rem" }} alt="example" src="./asserts/talent_scx.png" />
                            <p className="talent-info-extends">沈昌祥</p>
                            <p className="talent-info-extends">中国工程院院士</p>
                            <p className="talent-info-extends">国家集成电路产业发展咨询委员</p>
                        </Card>
                    </Col>
                    <Col span={6}>
                        <Card
                            hoverable
                            bordered={false}
                            style={{ margin: "0 0.1rem 0.1rem 0" }}
                            bodyStyle={{ padding: "0.1rem 0 0.1rem 0" }}
                        >
                            <img style={{ width: "1.44rem", height: "1.44rem" }} alt="example" src="./asserts/talent_zbl.png" />
                            <p className="talent-info-extends">张伯礼</p>
                            <p className="talent-info-extends">中国工程院院士</p>
                            <p className="talent-info-extends">天津中医药大学校长</p>
                        </Card>
                    </Col>
                    <Col span={6}>
                        <Card
                            hoverable
                            bordered={false}
                            style={{ margin: "0 0.1rem 0.1rem 0" }}
                            bodyStyle={{ padding: "0.1rem 0 0.1rem 0" }}
                        >
                            <img style={{ width: "1.44rem", height: "1.44rem" }} alt="example" src="./asserts/talent_yz.png" />
                            <p className="talent-info-extends">尤政</p>
                            <p className="talent-info-extends">中国工程院院士</p>
                            <p className="talent-info-extends">清华大学副校长</p>
                        </Card>
                    </Col>
                    <Col span={3} ></Col>
                </Row>
                <Row>
                    <Col span={3} ></Col>
                    <Col span={6}>
                        <Card
                            hoverable
                            bordered={false}
                            style={{ margin: "0 0.1rem 0.1rem 0" }}
                            bodyStyle={{ padding: "0.1rem 0 0.1rem 0" }}
                        >
                            <img style={{ width: "1.44rem", height: "1.44rem" }} alt="example" src="./asserts/talent_fjc.png" />
                            <p className="talent-info-extends">房建成</p>
                            <p className="talent-info-extends">中国科学院院士</p>
                            <p className="talent-info-extends">北京航空航天大学常务副校长</p>
                        </Card>
                    </Col>
                    <Col span={6}>
                        <Card
                            hoverable
                            bordered={false}
                            style={{ margin: "0 0.1rem 0.1rem 0" }}
                            bodyStyle={{ padding: "0.1rem 0 0.1rem 0" }}
                        >
                            <img style={{ width: "1.44rem", height: "1.44rem" }} alt="example" src="./asserts/talent_lm.png" />
                            <p className="talent-info-extends">刘明</p>
                            <p className="talent-info-extends">中国科学院院士</p>
                            <p className="talent-info-extends">中国科学院微电子研究所研究员</p>
                        </Card>
                    </Col>
                    <Col span={6}>
                        <Card
                            hoverable
                            bordered={false}
                            style={{ margin: "0 0.1rem 0.1rem 0" }}
                            bodyStyle={{ padding: "0.1rem 0 0.1rem 0" }}
                        >
                            <img style={{ width: "1.44rem", height: "1.44rem" }} alt="example" src="./asserts/talent_sl.png" />
                            <p className="talent-info-extends">宋梁</p>
                            <p className="talent-info-extends">加拿大国家工程院院士</p>
                            <p className="talent-info-extends">复旦大学特聘教授</p>
                        </Card>
                    </Col>
                    <Col span={3} ></Col>
                </Row>
                <Row>
                    <Col span={24} style={{ height: "0.43rem" }}></Col>
                </Row>
            </div>
        )
    }
}
export default TalentGrid;
