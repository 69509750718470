import React, { Component, Fragment } from 'react';
import './index.scss';
import Nav from '../components/Nav/index'
import BigPicZone from '../components/BigPicZone/index';
import TalentGrid from '../components/TalentGrid/index';
import ServiceGrid from '../components/ServiceGrid/index';
import Solution from '../components/Solution/index';
import Advantage from '../components/Advantage/index';
import Customer from '../components/Customer/index';
import Introduction from '../components/Introduction/index';
import Requirement from '../components/Requirement/requirement';
import FooterZone from '../components/FooterZone/index';

class Home extends Component {
    render() {
        return (
            <Fragment>
                <Nav />
                <BigPicZone />
                <TalentGrid />
                <ServiceGrid />
                <Solution />
                <Advantage />
                <Customer />
                <Introduction />
                <Requirement />
                <FooterZone />
            </Fragment>
        )
    }
}

export default Home;
